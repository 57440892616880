import React from "react";
import "../styles/About.css";
import { Container, Row, Col } from "react-bootstrap";
import Aboutside from "../assets/about.webp";
import bgimg from "../assets/main.webp";
import Footer from "./Footer";
import vision from "../assets/vision.jpg";
import mission from "../assets/mission.jpg";
import ceo from "../assets/ceo1.jpg";
import md from "../assets/md1.jpg";
import Productbg from "./Productbg";
function About() {
  return (
    <div>
      <div>
        <Productbg />
        <Container>
          <Row className="all-height">
            <Col lg={6} xs={12}>
              <img src={Aboutside} className="img-fluid" />
            </Col>
            <Col lg={5} style={{ backgroundColor: "snow" }} className="mt-5">
              <div>
                <h5 style={{ color: "#d57315", fontSize: "15px" }}>ABOUT</h5>
                <h2 className="title">High India International Marketing</h2>
              </div>
              <p className="content1">
                Established by Mr. Soundharajan Thilagavathy, our company, Uma
                Exports Private Limited, began its journey in 2024 as a private
                limited company under the Companies Act, 1956, with a focus on
                exporting building materials to Bangladesh. Recognizing the
                evolving market dynamics, we diversified into the export of
                agricultural produce and commodities in 1997, including rice,
                wheat, sugar, and spices. Over the years, we have expanded our
                product range to encompass a wide array of agricultural
                commodities, catering to diverse markets in Bangladesh,
                Malaysia, and Sri Lanka.
              </p>
              <p className="content1">
                Specializing in the trading and marketing of agricultural
                produce, we offer a comprehensive range of products, including
                sugar, spices, food grains, pulses, and agricultural feed. Our
                strategic approach to imports ensures a steady supply of
                lentils, faba beans, black Matpe, and toor (Pigeon Peas) from
                Canada, Australia, and Burma.
              </p>
            </Col>
            <Col lg={12} style={{ backgroundColor: "snow" }}>
              <p className="content1">
                Operating as B2B traders, we are highly specialized in sugar,
                corn, and dal, serving as a reliable link between suppliers and
                institutional parties such as manufacturers and exporters.
                Through our robust distribution network, we efficiently manage
                stocks and deliver bulk quantities to meet the demands of our
                clientele. Adhering to standard packing processes, we prioritize
                the preservation of quality and authentic taste in all our
                commodities.
              </p>
              <p className="content1">
                To adapt to market fluctuations, we have implemented a dynamic
                business strategy that allows us to seamlessly switch exports
                and imports based on demand and pricing variations. This
                proactive approach ensures our resilience and sustains our
                business momentum throughout the year.
              </p>

              <p className="content1">
                Driven by the vision of our promoter, Mr. Soundharajan
                Thilagavathy, and supported by our key management personnel and
                dedicated employees, we have achieved a positive trajectory in
                our business operations. Our customer-centric approach, coupled
                with strong relationships and cost-effective solutions,
                underpins our commitment to exceeding customer expectations and
                fostering long-term partnerships. We aim to continue delivering
                excellence while remaining responsive to the evolving needs of
                the agriculture industry.
              </p>
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          style={{
            backgroundColor: "#d57315",
            minHeight: "650px",
            marginBottom: "10px",
          }}
        >
          <Row>
            <Container>
              <Row>
                <Col
                  lg={{ span: 4, offset: 2 }}
                  style={{ backgroundColor: "white" }}
                  className="mt-5"
                >
                  <img
                    src={vision}
                    style={{
                      display: "block",
                      margin: "auto",
                      marginTop: "100px",
                      marginBottom: "50px",
                    }}
                  />

                  <h1>Vision</h1>
                  <p
                    style={{
                      marginBottom: "100px",
                      fontFamily: "sans-serif",
                      color: "#8b8b8b",
                      fontSize: "17px",
                      lineHeight: "30px",
                    }}
                  >
                    As a global leader in agricultural exports, our mission is
                    to ensure a stable market and equitable prices for our
                    customers. We are dedicated to consistently delivering
                    high-quality products on time, prioritizing a
                    customer-centric approach in all our endeavors. With a focus
                    on growth, performance, and excellence, we aim to establish
                    ourselves as the premier link between suppliers and
                    consumers worldwide. Continuously expanding our capabilities
                    along the value chain, we strive to become the preferred
                    choice, committed to upholding standards of
                  </p>
                </Col>
                <Col
                  lg={{ span: 4 }}
                  style={{ backgroundColor: "white" }}
                  className="mt-5"
                >
                  <img
                    src={mission}
                    style={{
                      display: "block",
                      margin: "auto",
                      marginTop: "100px",
                      marginBottom: "50px",
                    }}
                  />
                  <h1>Mission</h1>
                  <p
                    style={{
                      marginBottom: "100px",
                      fontFamily: "sans-serif",
                      color: "#8b8b8b",
                      fontSize: "17px",
                      lineHeight: "30px",
                    }}
                  >
                    With a profound respect for individuals both within and
                    beyond our company, as well as the wider community, our
                    mission is clear: to produce, distribute, and market the
                    highest quality agricultural products while upholding
                    environmentally responsible business practices. We are
                    committed to continually enhancing our capabilities through
                    investment in training to better meet the evolving needs of
                    our clients. Our dedication lies in ensuring that our
                    products maintain the pinnacle of purity, originality, and
                    freedom from contamination, adhering to the highest
                    standards across all analytical parameters of quality.
                  </p>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>

        <Container>
          <Row className="all-height1">
            <Col lg={12}>
              <h5 style={{ color: "#d57315", fontSize: "15px" }}>
                TEAM MEMBERS
              </h5>
              <h2 className="title">MANAGEMENT TEAM</h2>
            </Col>
            <Col
              lg={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={ceo}
                  alt="Avatar"
                  style={{
                    width: "300px",
                    height: "300px",
                  }}
                />
                <h3 style={{ textAlign: "center" }}>Thilagavathy</h3>
                <h6 style={{ textAlign: "center" }}>CEO</h6>
              </div>
            </Col>
            <Col
              lg={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={md}
                  alt="Avatar"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3 style={{ textAlign: "center" }}>Soundharajan</h3>
                <h6 style={{ textAlign: "center" }}>MD</h6>
              </div>
            </Col>
            {/* <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={avathar}
                  alt="Avatar"
                  style={{ width: "300px", height: "300px" }}
                />
                <h3>Name</h3>
                <p>Role</p>
              </div>
            </Col> */}
          </Row>
        </Container>
        {/* <Container>
          <Row>
            <Col lg={12}>
              <h5 style={{ color: "#d57315", fontSize: "30px" }}>
                CERTIFICATION
              </h5>
            </Col>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </Col>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </Col>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center mt-5"
            >
              <div>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </Col>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </Col>
            <Col
              lg={4}
              className="d-flex justify-content-center align-items-center"
            >
              <div>
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                  }}
                />
                <h3>Name</h3>
              </div>
            </Col>
          </Row>
        </Container> */}
        {/* <Container>
          <Row>
            <Col lg={12}>
              <h5
                style={{
                  color: "#d57315",
                  fontSize: "30px",
                  textAlign: "center",
                }}
              >
                Associates
              </h5>
            </Col>
          </Row>
          <Row>
            <Col
              lg={3}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={img1} height="200px" width="200px" alt="Image 1" />
            </Col>
            <Col
              lg={3}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={img2} height="200px" width="200px" alt="Image 2" />
            </Col>
            <Col
              lg={3}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={img3} height="100px" width="200px" alt="Image 3" />
            </Col>
            <Col
              lg={2}
              className="d-flex justify-content-center align-items-center"
            >
              <img src={img4} height="200px" width="200px" alt="Image 4" />
            </Col>
          </Row>
        </Container> */}
        <Footer />
      </div>
    </div>
  );
}

export default About;
