import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import "../styles/Footer.css";
import { MdMessage } from "react-icons/md";
import { IoMdMailOpen } from "react-icons/io";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4 mt-5">
      <Container>
        <Row>
          <Col md={4}>
            <h5>
              <span>Useful Links</span>
            </h5>
            <Link
              to="/about"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              About Us
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/products/sugar"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Our Products
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/material"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Material Documents
            </Link>
            {/* <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/quertly"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Financials
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/hr"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              careers
            </Link> */}
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/exports"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Exports
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/contact"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Contact Us
            </Link>
          </Col>
          <Col md={4}>
            <h5>
              <span>Our Products</span>
            </h5>
            <Link
              to="/ginger"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Spices
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/maize"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Flour
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/bengal"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Gram
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/sugar"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Sugar
            </Link>
            <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/chillis"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Others
            </Link>
            {/* <hr style={{ color: "#8b8b8b" }} />
            <Link
              to="/agro"
              className="nav-link"
              style={{
                color: "white",
                marginRight: "25px",
                marginTop: "-10px",
                fontSize: "17px",
              }}
            >
              <span
                style={{
                  marginRight: "5px",
                  color: "#d57315",
                  fontSize: "25px",
                  fontWeight: 800,
                }}
              >
                +
              </span>
              Agro Feed
            </Link> */}
          </Col>
          <Col md={4}>
            <h5>
              <span>Get In Touch</span>
            </h5>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaMapMarkerAlt
                style={{ marginRight: "20px", fontSize: "20px" }}
              />
              <div>
                <p style={{ margin: 0 }}>HIGH INDIA INTERNATIONAL MARKETING</p>
                <p style={{ margin: 0 }}>3/393 North street ,</p>
                <p style={{ margin: 0 }}>Thachanvayal - Kottur,</p>
                <p style={{ margin: 0 }}>MANNARGUDI ,</p>
                <p style={{ margin: 0 }}>THIRUVARUR - 614 017</p>
                <p style={{ margin: 0 }}>TAMIL NADU - INDIA</p>
              </div>
            </div>
            <hr style={{ color: "#8b8b8b" }} />
            <p>
              <FaPhoneAlt style={{ marginRight: "20px" }} />
              +91-73588 05707
            </p>
            <hr style={{ color: "#8b8b8b" }} />
            <p>
              <FaEnvelope style={{ marginRight: "25px" }} />
              <a
                href="mailto:info@umaexports.net.in"
                style={{ color: "white", textDecoration: "none" }}
              >
                hiimhighindia@gmail.com
              </a>
            </p>
            <div style={{ display: "flex", marginTop: "40px" }}>
              <a href="https://www.facebook.com/profile.php?id=61558628147059&mibextid=ZbWKwL">
                <FaFacebook
                  style={{
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "white",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/hiimhighindia?utm_source=qr&igsh=Nno4bG1uemhrMHJ6">
                <FaInstagram
                  style={{
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "white",
                  }}
                />
              </a>
              <a href="https://x.com/hiimhighindia?t=CHUbbCRi6ddpHQYLszkZgA&s=08">
                <FaTwitter
                  style={{
                    marginRight: "30px",
                    fontSize: "30px",
                    color: "white",
                  }}
                />
              </a>
            </div>
            <a
              className="whats-app"
              href="https://wa.me/7358805707"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="my-float" />
            </a>
            <a
              className="phone"
              href="tel:73588 05707"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaPhoneAlt className="my-float1" />
            </a>
            <NavLink
              to="/contact"
              className="enquiry"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoMdMailOpen className="my-float2" />
            </NavLink>
          </Col>
        </Row>
      </Container>
      <div className="copyright">
        &copy; 2024 HIGH INDIA INTERNATIONAL MARKETING. All Rights Reserved.
        <p style={{ marginBottom: "-20px" }}>
          Developed by
          <a
            href="https://vebbox.com/"
            style={{ color: "white", textDecoration: "none" }}
          >
            Vebbox Software solutions
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
