import React from "react";
import "../styles/Sugar.css";
import { Container, Row, Col } from "react-bootstrap";
import bgimg from "../assets/main.webp";
import Footer from "../components/Footer";
import { NavLink } from "react-router-dom";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import pdf from "../assets/PDF_file_icon.svg.png";
import banner from "../assets/widget-banner.jpg";
import doc1 from "../assets/registration.pdf";
import doc2 from "../assets/import.pdf";
import doc3 from "../assets/gst.pdf";
import Productbg from "../components/Productbg";
function Material() {
  return (
    <div>
      <Productbg />
      <Container>
        <Row style={{ marginTop: "-300px", marginBottom: "100px" }}>
          <Col lg={3}>
            <div style={{ position: "relative" }}>
              <img
                src={banner}
                style={{ zIndex: "-1", position: "absolute", top: 0, left: 0 }}
              />
              <div style={{ position: "relative", zIndex: 1, padding: "40px" }}>
                <PhoneOutlinedIcon
                  style={{ fontSize: "50px", color: "d57315" }}
                />
                <p
                  style={{
                    color: "white",
                    marginTop: "30px",
                    fontSize: "30px",
                  }}
                >
                  How We Can Help You
                </p>
                <p style={{ color: "white", fontSize: "20px" }}>
                  If you need any help, contact us
                </p>
                <PhoneOutlinedIcon
                  style={{ fontSize: "20px", color: "d57315" }}
                />
                <span style={{ color: "white" }}>+91-73588 05707</span>
              </div>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="section-title clearfix">
              <div className="title-header">
                <h5 style={{ color: "#d57315", fontSize: "25px" }}>
                  Material Document
                </h5>
                <div>
                  <a href={doc1} target="_blank">
                    <img
                      src={pdf}
                      alt="Avatar"
                      style={{
                        width: "200px",
                        height: "200px",
                        cursor: "pointer", // Add cursor style to indicate clickable
                      }}
                    />
                  </a>
                  <h5 style={{ color: "black", textAlign: "center" }}>
                    Registration
                  </h5>
                </div>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 3 }}
            className="d-flex justify-content-center align-items-center mt-5"
          >
            <div>
              <a href={doc2} target="_blank">
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                    cursor: "pointer", // Add cursor style to indicate clickable
                  }}
                />
              </a>
              <h5 style={{ textAlign: "center" }}>Import/Export</h5>
            </div>
          </Col>
          <Col
            lg={3}
            className="d-flex justify-content-center align-items-center mt-5"
          >
            <div>
              <a href={doc3} target="_blank">
                <img
                  src={pdf}
                  alt="Avatar"
                  style={{
                    width: "200px",
                    height: "200px",
                    cursor: "pointer", // Add cursor style to indicate clickable
                  }}
                />
              </a>
              <h5 style={{ textAlign: "center" }}>GST</h5>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}

export default Material;
