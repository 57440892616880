import React from "react";
import "../styles/Home.css";
import { Container, Card, Col, Button, Row } from "react-bootstrap";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Carousel from "./Carousel";
import Aboutside from "../assets/dsvsdfdasf.webp";
import About1 from "../assets/sadfsf.webp";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  NavLink,
  Routes,
  Route,
} from "react-router-dom";
function Home() {
  return (
    <div>
      <Carousel />
      <div>
        <Container>
          <Row className="all-height">
            <Col lg={6} xs={12}>
              <img src={Aboutside} className="img-fluid" />
            </Col>
            <Col
              lg={6}
              xs={12}
              style={{ backgroundColor: "snow" }}
              className="mt-5"
            >
              <div>
                <h5 style={{ color: "#d57315", fontSize: "15px" }}>ABOUT</h5>
                <h2 className="title">HIGH INDIA INTERNATIONAL MARKETING   </h2>
              </div>
              <div className="content-wrapper" >
              <p className="content1">
              Our journey began when Mr. Soundharajan Thilagavathy established Uma Exports Private Limited under the Companies Act, 1956, receiving the Certificate of Incorporation in July 2023 from the Registrar of Companies, West Bengal. Originally focused on exporting building materials such as marble, granite, and marble chips to Bangladesh, we recognized the burgeoning demand in the Bangladeshi market.In 1997, seizing an opportunity to diversify, we transitioned into exporting agricultural produce and commodities. Since then, our commitment to quality and authenticity has remained unwavering. Our portfolio now includes staples like rice, wheat, sugar, and spices, ensuring that the essence and flavor of these commodities remain true to their origins.Over the years, we have expanded our reach beyond Bangladesh, venturing into markets in Malaysia and Sri Lanka. Despite our growth and diversification, our dedication to delivering top-notch products with unmatched quality and authentic taste remains at the core of our business ethos.
              </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-4" style={{ backgroundColor: "#d57315" }}>
            <Col xs={12} md={4}>
              <div style={{ backgroundColor: "#d57315", height: "110px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "110px",
                    padding: "40px",
                    marginTop: "40px",
                  }}
                >
                  <SearchOutlinedIcon
                    style={{
                      color: "#d57315",
                      width: "80px",
                      height: "80px",
                      marginTop: "-20px",
                      paddingLeft: "10px",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "18px",
                      marginTop: "-70px",
                      marginLeft: "80px",
                    }}
                  >
                    <b>Quality</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "80px",
                      color: "#232323",
                      fontSize: "15px",
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{ backgroundColor: "#d57315", height: "110px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "110px",
                    padding: "40px",
                    marginTop: "40px",
                  }}
                >
                  <SearchOutlinedIcon
                    style={{
                      color: "#d57315",
                      width: "80px",
                      height: "80px",
                      marginTop: "-20px",
                      paddingLeft: "10px",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "18px",
                      marginTop: "-70px",
                      marginLeft: "80px",
                    }}
                  >
                    <b>CSR</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "80px",
                      color: "#232323",
                      fontSize: "15px",
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div style={{ backgroundColor: "#d57315", height: "150px" }}>
                <div
                  style={{
                    backgroundColor: "white",
                    height: "110px",
                    padding: "40px",
                    marginTop: "40px",
                  }}
                >
                  <SearchOutlinedIcon
                    style={{
                      color: "#d57315",
                      width: "80px",
                      height: "80px",
                      marginTop: "-20px",
                      paddingLeft: "10px",
                    }}
                  />
                  <p
                    className="phoneno"
                    style={{
                      fontSize: "18px",
                      marginTop: "-70px",
                      marginLeft: "80px",
                    }}
                  >
                    <b>Careers</b>
                  </p>
                  <p
                    style={{
                      marginLeft: "80px",
                      color: "#232323",
                      fontSize: "15px",
                    }}
                  >
                    Read More
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mt-5" style={{ backgroundColor: "snow" }}>
              <div>
                <h5 style={{ color: "#d57315", fontSize: "15px" }}>EXPLORING AGRICULTURAL TRADING EXCELLENCE</h5>
                <h2 className="title">   HIGH INDIA INTERNATIONAL MARKETING</h2>

                <p className="content1">
                Our company specializes in the trading and marketing of a wide range of agricultural produce and commodities. Our diverse portfolio includes essentials such as sugar, along with a variety of spices like dry red chilies, turmeric, coriander, and cumin seeds. Additionally, we offer food grains such as rice, wheat, corn, sorghum, and tea, catering to the varied needs of our clients.In the realm of pulses and agricultural feed, we provide high-quality products like soybean meal and rice bran de-oiled cake. Furthermore, we facilitate the importation of lentils, faba beans, black Matpe, and toor (Pigeon Peas) into India, procuring bulk quantities from trusted sources in Canada, Australia, and Burma.As B2B traders, we specialize in sugar, corn, and dal, serving as a reliable link between producers and institutional parties such as manufacturers and exporters. Our robust distribution network ensures timely delivery of bulk quantities to meet the demands of our clients.To uphold the integrity of our products, we adhere to a standard packing process that safeguards the quality and authentic taste of our commodities. This commitment to excellence underscores our dedication to providing superior products and services to our valued customers.
                </p>
                <a href="/about" className="btn1">
                  Read More
                </a> 
                {/* <button className="btn1">Read More</button> */}

                <div className="call">
                  <p>Call to ask any question</p>
                </div>
                <div className="contact">
                  <h5>+91-7358805707</h5>
                </div>
              </div>
            </Col>
            <Col lg={{ span: 5, offset: 1 }} className="mt-5 ">
              <img src={About1} className="img-fluid" />
            </Col>
          </Row>
        </Container>
        <Container
          fluid
          style={{ backgroundColor: "#f7f7f6" }}
          className="mt-5"
        >
          <Container>
            <Row>
              <h5 style={{ color: "#d57315", fontSize: "15px" }}>
                WHAT WE OFFER
              </h5>
              <h2 className="title">Our Products</h2>
              <Col lg="4" className="mt-5">
                <Card style={{ backgroundColor: "#fff",minHeight:"270px" }}>
                  <Card.Body>
                    <Card.Title>Ginger</Card.Title>
                    <Card.Text style={{ color: "#8b8b8b" }}>
                    The ginger content in foods can vary depending on the recipe and how it's prepared. Fresh ginger root typically contains about 1-2% gingerol by weight, which is the compound responsible for ginger's characteristic flavor and potential health benefits.
                    </Card.Text>
                    <NavLink to="/ginger">
                    <button className="btn2">Learn More</button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" className="mt-5">
                <Card style={{ backgroundColor: "#fff",minHeight:"270px" }}>
                  <Card.Body>
                    <Card.Title>Maize Flour</Card.Title>
                    <Card.Text style={{ color: "#8b8b8b" }}>
                    Maize flour is a versatile ingredient that has been a dietary staple in many cultures for centuries. It boasts a rich content of nutrients and bioactive compounds, making it not only a flavorful addition to various dishes but also a source of potential health benefits.
                    </Card.Text>
                    <NavLink to="/maize">
                    <button className="btn2">Learn More</button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" className="mt-5">
                <Card style={{ backgroundColor: "#fff",minHeight:"270px" }}>
                  <Card.Body>
                    <Card.Title>Bengal Gram</Card.Title>
                    <Card.Text style={{ color: "#8b8b8b" }}>
      One of the primary components of Bengal gram is protein, making it an excellent plant-based protein source for vegetarians and vegans. Protein is essential for muscle growth and repair, as well as for the production of enzymes and hormones within the body.
                    </Card.Text>
                    <NavLink to="/bengal">
                    <button className="btn2">Learn More</button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4" className="mt-5">
                <Card style={{ backgroundColor: "#fff",minHeight:"270px" }}>
                  <Card.Body>
                    <Card.Title>Sugar candy,</Card.Title>
                    <Card.Text style={{ color: "#8b8b8b" }}>
                    Sugar candy, also known as rock candy or crystallized sugar, is a sweet treat that has been enjoyed for centuries in various cultures around the world. Made from sucrose, sugar candy is created through a process of crystallization, where sugar molecules form into solid crystals.
                    </Card.Text>
                    <NavLink to="/sugar">
                    <button className="btn2">Learn More</button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" className="mt-5">
                <Card style={{ backgroundColor: "#fff",minHeight:"270px" }}>
                  <Card.Body>
                    <Card.Title>Honey</Card.Title>
                    <Card.Text style={{ color: "#8b8b8b" }}>
                    Honey, often referred to as nature's golden elixir, is a natural sweetener produced by honey bees from the nectar of flowers. It has been treasured by civilizations throughout history for its delicious flavor, versatility, and numerous health benefits. 
                    </Card.Text>
                    <NavLink to="/honey">
                    <button className="btn2">Learn More</button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4" className="mt-5 mb-5">
                <Card style={{ backgroundColor: "#fff",minHeight:"270px" }}>
                  <Card.Body>
                    <Card.Title>Chili </Card.Title>
                    <Card.Text style={{ color: "#8b8b8b" }}>
                    Chili peppers, with their fiery heat and vibrant colors, are a staple ingredient in cuisines around the world, adding both flavor and spice to a wide variety of dishes. These peppers belong to the Capsicum genus and come in a range of shapes, sizes, and heat levels, from mild and sweet to intensely hot.
                    </Card.Text>
                    <NavLink to="/chillis">
                    <button className="btn2">Learn More</button>
                    </NavLink>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
